export const ArticlePrevNext = () => import('./../../components/content/ArticlePrevNext.vue')
export const Banner = () => import('./../../components/content/Banner.vue')
export const BlogPostHeaderImg = () => import('./../../components/content/BlogPostHeaderImg.vue')
export const BlogPostList = () => import('./../../components/content/BlogPostList.vue')
export const DocDate = () => import('./../../components/content/DocDate.vue')
export const PageChildren = () => import('./../../components/content/PageChildren.vue')
export const PagePrevNext = () => import('./../../components/content/PagePrevNext.vue')
export const PageTocList = () => import('./../../components/content/PageTocList.vue')
export const ProsePre = () => import('./../../components/content/ProsePre.vue')
export const ProseStrong = () => import('./../../components/content/ProseStrong.vue')
export const QueryCollection = () => import('./../../components/content/QueryCollection.vue')
export const QueryNavigation = () => import('./../../components/content/QueryNavigation.vue')
export const QueryPlayground = () => import('./../../components/content/QueryPlayground.vue')
export const SlotInjection = () => import('./../../components/content/SlotInjection.vue')
export const StripeCreatorRevenue = () => import('./../../components/content/StripeCreatorRevenue.vue')
export const StripeMinimumTransferAmount = () => import('./../../components/content/StripeMinimumTransferAmount.vue')
export const StripePlatformCurrency = () => import('./../../components/content/StripePlatformCurrency.vue')
export const StripePlatformFee = () => import('./../../components/content/StripePlatformFee.vue')
export const CoreYoutubeVideo = () => import('./../../components/core/YoutubeVideo.vue')
export const CoreImageFullscreen = () => import('./../../components/core/image/Fullscreen.vue')
export const globalComponents: string[] = ["ProseA","ProseBlockquote","ProseCode","ProseEm","ProseH1","ProseH2","ProseH3","ProseH4","ProseH5","ProseH6","ProseHr","ProseImg","ProseLi","ProseOl","ProseP","ProseScript","ProseTable","ProseTbody","ProseTd","ProseTh","ProseThead","ProseTr","ProseUl"]
export const localComponents: string[] = ["ArticlePrevNext","Banner","BlogPostHeaderImg","BlogPostList","DocDate","PageChildren","PagePrevNext","PageTocList","ProsePre","ProseStrong","QueryCollection","QueryNavigation","QueryPlayground","SlotInjection","StripeCreatorRevenue","StripeMinimumTransferAmount","StripePlatformCurrency","StripePlatformFee","CoreYoutubeVideo","CoreImageFullscreen"]